const Selesai = ({ data, containerRef2 }) => {
  return (
    <div className="flex-1 p-1 rounded-lg shadow-2xl">
      <div className="flex flex-col bg-emerald-600 rounded-lg p-1 mb-1">
        <span className="font-poppins-bold text-xl text-white text-center animate-bounce">
          TNKB SUDAH DICETAK
        </span>
        <div className="flex gap-1">
          <span className="bg-gray-300/70 w-full text-center p-1 rounded-md text-black font-bold text-xl">
            R2 / R3 ({data.totalDataR2 ? data.totalDataR2 : "0"})
          </span>
          <span className="bg-gray-300/70 w-full text-center p-1 rounded-md text-black font-bold text-xl">
            R4 / LEBIH ({data.totalDdataR4 ? data.totalDdataR4 : "0"})
          </span>
        </div>
      </div>
      <div
        className="flex flex-row gap-2 overflow-hidden justify-center h-[647px] mt-2"
        ref={containerRef2}
      >
        <div className="flex-1">
          {data.dataR2 && data.dataR2.length > 0 ? (
            data.dataR2.map((item, idx) => (
              <div
                key={idx}
                className={`${
                  item.warnaTnkb === "MERAH"
                    ? "bg-red-500 text-white ring-white"
                    : item.warnaTnkb === "HITAM"
                    ? "bg-black ring-white"
                    : item.warnaTnkb === "KUNING"
                    ? "bg-yellow-500 ring-black"
                    : item.warnaTnkb === "HIJAU"
                    ? "bg-emerald-500 ring-black"
                    : "bg-white ring-black"
                } rounded-md ring-2 mt-2`}
              >
                <div
                  className={`flex justify-between pr-4 pl-4 ${
                    item.warnaTnkb === "HITAM"
                      ? "text-white"
                      : item.warnaTnkb === "MERAH"
                      ? "text-white"
                      : "text-black"
                  } text-3xl font-poppins-bold`}
                >
                  <span className="font-bold text-left">
                    {item.kodeWilPolda}
                  </span>
                  <span className="font-bold text-center">{item.noPol}</span>
                  <span className="font-bold text-right">
                    {item.kodeWilayah}
                  </span>
                </div>
                <span
                  className={`flex justify-end items-center font-semibold pr-4 pl-4 rounded-b-md text-2xl ${
                    item.warnaTnkb === "HITAM"
                      ? "text-white"
                      : item.warnaTnkb === "MERAH"
                      ? "text-white"
                      : "text-black"
                  } ${item.kodeBbm === 1 ? "bg-blue-500" : ""}`}
                >
                  {item.blnThn}
                </span>
              </div>
            ))
          ) : (
            <div
              className={`bg-white ring-black w-[200px] pr-4 pl-4 rounded-md ring-2 flex flex-col items-center drop-shadow-lg max-h-20`}
            >
              <div className="flex justify-between w-full">
                <span className="font-bold text-lg text-center flex-grow">
                  {"BELUM ADA DATA"}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="flex-1">
          {data.dataR4 && data.dataR4.length > 0 ? (
            data.dataR4.map((item, idx) => (
              <div
                key={idx}
                className={`${
                  item.warnaTnkb === "MERAH"
                    ? "bg-red-500 text-white ring-white"
                    : item.warnaTnkb === "HITAM"
                    ? "bg-black ring-white"
                    : item.warnaTnkb === "KUNING"
                    ? "bg-yellow-500 ring-black"
                    : item.warnaTnkb === "HIJAU"
                    ? "bg-emerald-500 ring-black"
                    : "bg-white ring-black "
                } rounded-md ring-2 mt-2`}
              >
                <div
                  className={`flex justify-between pr-4 pl-4 ${
                    item.warnaTnkb === "HITAM"
                      ? "text-white"
                      : item.warnaTnkb === "MERAH"
                      ? "text-white"
                      : "text-black"
                  } text-3xl font-poppins-bold`}
                >
                  <span className="font-bold text-left">
                    {item.kodeWilPolda}
                  </span>
                  <span className="font-bold text-center">{item.noPol}</span>
                  <span className="font-bold text-right">
                    {item.kodeWilayah}
                  </span>
                </div>
                <span
                  className={`flex justify-center items-center font-semibold rounded-b-md text-2xl ${
                    item.warnaTnkb === "HITAM"
                      ? "text-white"
                      : item.warnaTnkb === "MERAH"
                      ? "text-white"
                      : "text-black"
                  } ${item.kodeBbm === 1 ? "bg-blue-500" : ""}`}
                >
                  {item.blnThn}
                </span>
              </div>
            ))
          ) : (
            <div
              className={`bg-white ring-black w-[200px] pr-4 pl-4 rounded-md ring-2 flex flex-col items-center drop-shadow-lg max-h-20`}
            >
              <div className="flex justify-between w-full">
                <span className="font-bold text-lg text-center flex-grow">
                  {"BELUM ADA DATA"}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Selesai;
